.Hero{
    color: white;
    font-weight: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    margin-top: 10vh;
}
.h1t{
    font-size: 3em;
}
.h3t{
    font-size: 1em;
    margin-top: 90px;
    margin-bottom: 100px;
}