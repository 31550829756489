.Body{
 
    text-align: center;
    position: relative;
    color: white;
    width: 100%;
    margin-top: 150vh;
    margin-bottom: 50vh;
    height: fit-content;
    max-height: 70vh;


} 
.h1{
    font-size: 3em;
    margin-bottom: 60vh;
}
.diamond {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    margin:auto;
    margin-bottom: 50vh;
    width: 250px;
    height: 250px;
  }
  
  .diamond img {
    max-width: 70px;
    max-height: 70px;
    object-fit: cover;
    margin: 5px;
  }
  @media screen and (max-width: 768px) {
    .diamond img {
        max-width: 55px;
        max-height: 55px;
    }
  }
.logoIMG{
    max-width: 55px;
    max-height: 55px;
}
.imgContainer{
    text-align: center;
    
}
.imgBody{
    border-radius: 20px;
    max-width: 50vw;
    min-width: 400px;
    max-height: 100vh;
    min-height: 300px;
    margin: 5px;
    margin-bottom: 30vh;
    
   
}
.imgBody:hover{
    transform: scale(1.05);
}
.contact{
    margin:auto;
}
.form{
    margin: 10vh;
}
.label{
    margin: 10px;
}
.input{
    background-color: black;
}
.textarea{
    margin: 10px;
    min-width: 50vw;
    min-height: 50vh;
    max-width: 600px;
}
.button{
    border-radius: 10px;
    background-color: #ff4d5a;
    color: black;
}
.button:hover{
    transform: scale(1.05);
}