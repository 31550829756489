.Footer{
    background-color: #021012;
    color: white;
    text-align: center;
    position:relative;
    margin-top: auto;
    width: 100%;
    height: 10vh;
}
.p{
    padding: 1vh;
    margin-top: 1vh;
    background-color: inherit;
    width: 100%;
}
.img{
    margin: 15px;
    width:40px;
    height:40px;
}
.img:hover{
    transform: scale(1.1);
}